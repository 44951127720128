// Libs
import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from "../hooks/useAuth";
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { sendCancelEventRequest } from '../api/Events';
import { retrieveAttendees, updateAttendance } from "../api/Bookings";
// Helpers
import { showFriendlyDate, showFriendlyTime, internationalizePhoneNumber } from "../helpers/Utilities";
// Types
import { CancelClassItem } from "../types/types";
// Components
import CancelClassConfirmation from '../components/Dialog/CancelClassConfirmation';
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Avatar, Box, Button, Card, CardContent, Checkbox, Container, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
// Material-UI Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function Attendees() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { user, isUserLoading } = useAuth();
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const [isDialogOpen, setDialogOpen] = useState(false);
  // State to keep track of the class item to be cancelled
  const [classToCancel, setClassToCancel] = useState<CancelClassItem | null>(null);
  const [disableCancel, setDisableCancel] = useState(false);

    // Redirect user if not a club user
    useClubRedirect(user, isUserLoading);

  // Handler to send cancel class request
  const handeCancelRequest = async (userId: string, eventId: string) => {
    try {
      const response = await sendCancelEventRequest(userId, eventId);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['clubBookingData', eventId] });
        setDisableCancel(true);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };
  
  // Handler to open the dialog and set the class to cancel
  const handleCancelClick = (classItem: any) => {
    setClassToCancel(classItem);
    setDialogOpen(true);
  };

  // Handler for when user confirms cancellation
  const handleConfirmCancellation = () => {
    handeCancelRequest(user?.dataValues?.id, eventId ?? '');
    // Close the dialog
    setDialogOpen(false);
    // Reset the class to cancel
    setClassToCancel(null);
  };

  // Handler for closing the dialog without action
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setClassToCancel(null);
  };

  const queryClient = useQueryClient();
  const { data: bookingData, error, isLoading } = useQuery({
    queryKey: ['clubBookingData', eventId],
    queryFn: () => retrieveAttendees(eventId || ''),
    enabled: !!eventId
  });
  
  // Handler to update attendance
  const handleUpdateAttendanceRequest = async (bookingId: string, userId: string, attend: boolean, familyMemberId: string) => {
    try {
      const response = await updateAttendance(bookingId, userId, attend, familyMemberId);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['clubBookingData', eventId] });
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };
  
  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
      {bookingData.length > 0 && (
        <>
        <Box textAlign={'center'} sx={{ marginTop: '15px' }}>
          <Typography sx={{fontWeight: '500'}}>Bookings for {bookingData[0]?.event_name}</Typography>
          <Typography sx={{fontWeight: '500'}}>{showFriendlyDate(bookingData[0]?.date, user?.dataValues?.timezone)} - {showFriendlyTime(bookingData[0]?.date, user?.dataValues?.timezone)}</Typography>
        </Box>
        </>
      )}
       <Box sx={{marginTop: '5px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2}} textAlign={'center'}>
          <Button variant="contained" onClick={() => navigate('/clublanding')}>Home</Button>
          <Button disabled={disableCancel} variant="contained" color="error" onClick={() => handleCancelClick(bookingData[0])}>Cancel</Button>
          <Button disabled={disableCancel} variant="contained" onClick={() => console.log('clicked series')}>Series</Button>
          <CancelClassConfirmation 
            open={isDialogOpen} 
            onClose={handleCloseDialog} 
            onConfirm={handleConfirmCancellation} 
            message="Are you sure you want to cancel this class?"
          />
        </Box>
      {Array.isArray(bookingData) && bookingData.length === 0 ? (
        <Box textAlign={'center'} sx={{marginTop: '15px'}}>
        <Card >
          <CardContent>
            <Box>
              <Typography sx={{fontWeight: '500'}}>No Attendees Booked</Typography>
              <LocalActivityIcon fontSize="large"/>
            </Box>
            <Button variant="contained" onClick={() => navigate('/searchclasses')}>Go Back</Button>
          </CardContent>
        </Card>
      </Box>
      ) : (
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: '500', marginTop: '15px', textAlign: 'center' }}>
            Attendee List ({bookingData.length})</Typography>
          {Array.isArray(bookingData) && bookingData.map((booking: any) => (
            <Box key={booking.id}>
              <List sx={{paddingRight: 0, paddingLeft: 0}}>
                <ListItem sx={{paddingRight: 0, paddingLeft: 0}}
                  secondaryAction={
                    <>
                    <a href={`tel:${internationalizePhoneNumber(booking.user_telephone)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <IconButton edge="end" aria-label="phone" sx={{ marginRight: { xs: 1, sm: 2, md: 3 } }}>
                        <LocalPhoneIcon />
                      </IconButton>
                    </a>
                    <a href={`sms:${internationalizePhoneNumber(booking.user_telephone)}`}>
                      <IconButton>
                        <ChatBubbleOutlineIcon />
                      </IconButton>
                    </a>
                    <a href={`mailto:${booking.user_email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <IconButton edge="end" aria-label="email" sx={{ marginRight: { xs: 1, sm: 2, md: 3 } }}>
                        <MailOutlineIcon />
                      </IconButton>
                    </a>
                    <Checkbox
                      id={`attend-checkbox-${booking.id}`}
                      checked={booking.did_attend}
                      onChange={(event) => {
                        const value = event.target.checked;
                        handleUpdateAttendanceRequest(
                          booking.id,
                          booking.user_id,
                          value,
                          booking.family_member_id
                        );
                      }}
                      edge="end"
                      disableRipple
                      inputProps={{ 'aria-labelledby': `checkbox-list-label-${booking.user_id}` }}
                      sx={{ '&:hover': { backgroundColor: 'transparent' }, marginLeft: 'auto' }}
                    />
                    </>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={booking.user_profile_image_url}>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    {booking.family_member_details ? (
                      <>
                        {booking.family_member_details.first_name} {booking.family_member_details.last_name}
                      </>
                      ) : (
                      <>
                        {booking.user_first_name} {booking.user_last_name}
                      </>
                    )}
                  </ListItemText>

                </ListItem>
              </List>
            </Box>
          ))}
        </Grid>
      )}
    </Container>
  );
}