import React, { useState } from 'react';
// Helpers
import { internationalizePhoneNumber } from "../../helpers/Utilities";
// Material UI
import { Avatar, Box, IconButton, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Popover, Typography } from '@mui/material';
// Material UI Icons
import GroupIcon from '@mui/icons-material/Group';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function MemberList({member}: {member: any}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'family-members-popover' : undefined;

  return ( 
    <Grid item xs={12} md={6}>
      <Box key={member.id}>
        <List sx={{paddingRight: 0, paddingLeft: 0}}>
          <ListItem sx={{ paddingRight: 0, paddingLeft: 0 }}>
            <ListItemAvatar>
              <Avatar src={member.member_profile_image_url}>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                {member.first_name} {member.last_name}
                {member.family_members && member.family_members.length > 0 && (
                  <>
                    <IconButton onClick={handleClick} sx={{ marginLeft: 1 }}>
                      <GroupIcon />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">Family Members</Typography>
                        {member.family_members.map((familyMember: any, index: number) => (
                          <Typography key={index}>{familyMember.first_name} {familyMember.last_name}</Typography>
                        ))}
                      </Box>
                    </Popover>
                  </>
                )}
              </Box>
            </ListItemText>
            <ListItemSecondaryAction>
              <a href={`tel:${internationalizePhoneNumber(member.telephone)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <IconButton edge="end" aria-label="phone" sx={{ marginRight: { xs: 0, sm: 1, md: 2 } }}>
                  <LocalPhoneIcon />
                </IconButton>
              </a>
              <a href={`sms:${internationalizePhoneNumber(member.telephone)}`}>
                <IconButton>
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </a>
              <a href={`mailto:${member.email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                <IconButton edge="end" aria-label="email" sx={{ marginRight: { xs: 1, sm: 2, md: 3 } }}>
                  <MailOutlineIcon />
                </IconButton>
              </a>
           </ListItemSecondaryAction>
         </ListItem>
        </List>
      </Box>
  </Grid>
  );
}