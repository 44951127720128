// Libs
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query'
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from "../hooks/useAuth";
import { useShowAlert } from "../hooks/useShowAlert";
import useMemberRedirect from '../hooks/useMemberRedirect';
// Api Requests
import { getEventById } from '../api/Events';
import { getBookingsByEventId, createNewBooking } from '../api/Bookings';
// Helpers
import { showFriendlyDate, showFriendlyTime } from '../helpers/Utilities';
// Types
import { Booking, FamilyMember, User } from "../types/types";
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Box, Button, Checkbox, Container, FormControlLabel, Typography } from '@mui/material';
// Material-UI Icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';

const isMemberAlreadyBooked = (bookedMembers: Booking[], member: User) => {
  if (!member || bookedMembers.length === 0) {
    return false;
  }
  // Check if user exists in bookedMembers
  return bookedMembers.some(bookedMember => bookedMember.family_member_id === null && bookedMember.user_id === member.dataValues.id);
};

const isFamilyMemberAlreadyBooked = (bookedMembers: Booking[], familyMember: FamilyMember) => {
  if (!familyMember || bookedMembers.length === 0) {
    return false;
  }

  // Check if family member exists in bookedMembers
  return bookedMembers.some(bookedMember => {
    if (bookedMember.family_member_id === familyMember.id) {
      return true;
    }
    return false;
  });
};

export default function BookClass() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { eventId } = useParams();
  const { user, isUserLoading } = useAuth();
  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
  const isSubmitButtonDisabled = checkedUsers.length === 0;

  // Redirect user if not a member user
  useMemberRedirect(user, isUserLoading);

  const handleCheckboxChange = (memberId: string) => {
    setCheckedUsers((prevCheckedUsers: any) => {
      if (prevCheckedUsers.includes(memberId)) {
        // Uncheck the checkbox
        return prevCheckedUsers.filter((id: string) => id !== memberId);
      } else {
        // Check the checkbox
        return [...prevCheckedUsers, memberId];
      }
    });
  };
  
  const queryClient = useQueryClient();
  const { data: classData, error: classError, isLoading: isClassLoading } = useQuery({
    queryKey: ['eventsData', eventId,],
    queryFn: () => getEventById(eventId ?? ''),
    enabled: !!eventId,
  });
  // Retrieve existing bookings for this class
  const { data: bookedMembers, error: existingBookingsError, isLoading: isExistingBookingsLoading } = useQuery({
    queryKey: ['existingBookings', eventId],
    queryFn: () => getBookingsByEventId(eventId ?? ''),
    enabled: !!eventId,
  });    

  const handleCreateNewBookingRequest = async (body: any) => {
    try {
      const response = await createNewBooking(body);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['existingBookings', eventId] });
        queryClient.invalidateQueries({ queryKey: ['eventsData', eventId] });
        setCheckedUsers([]);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
        setCheckedUsers([]);
      }

    } catch (error) {
      showAlert('Something Went Wrong', 'Please Try Again', 'error');
      setCheckedUsers([]);
    }
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const formBody = JSON.stringify({
      eventName: classData?.name,
      eventLimit: classData?.event_limit,
      eventId: eventId,
      userId: user?.dataValues?.id,
      date: classData?.date,
      clubName: classData?.club_name,
      familyToBook: checkedUsers,
      spacesBooked: checkedUsers.length > 0 ? checkedUsers.length : 1,
    });
    handleCreateNewBookingRequest(formBody);
  };

  if (classError) return <p>An error has occurred: {classError.message}</p>;
  if (existingBookingsError) return <p>An error has occurred: {existingBookingsError.message}</p>;

  // If still loading, show loading state
  if (isUserLoading || isClassLoading || isExistingBookingsLoading) {
    return <Loading text="" />
  }

  return(
    <Container component="main" maxWidth="xs" sx={{ pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
    <div>
      {classData ? (
        <>
        <Box textAlign={'center'} sx={{ marginTop: "15px" }}>
          <Typography sx={{ fontWeight: '600' }}>{classData.name}</Typography>
          <Typography sx={{ fontWeight: '600' }}>{classData.club_name}</Typography>
          <Box textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CalendarMonthOutlinedIcon sx={{ marginRight: '5px' }} />
            <Typography sx={{ fontWeight: '600' }}>{showFriendlyDate(classData.date, user?.dataValues?.timezone)}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: '15px' }} justifyContent={'center'} textAlign={'center'} display={'flex'} alignItems={'space-between'}>
          {/* Class Time */}
          <ScheduleIcon sx={{ marginRight: '5px' }}/>
          <Typography sx={{ marginRight: '5px' }}>{showFriendlyTime(classData.date, user?.dataValues?.timezone)}</Typography>
          
          {/* Class Duration */}
          <TimelapseIcon sx={{ marginRight: '5px' }} />
          <Typography sx={{ marginRight: '5px' }}>{classData.duration} Mins</Typography>
        
          {/* Spaces Left */}
          <PersonIcon sx={{ marginRight: '5px' }} />
          <Typography sx={{ marginRight: '5px' }}>
            {classData.event_limit - classData.spaces_booked - checkedUsers.length} 
            {' '}
            {classData.event_limit - classData.spaces_booked - checkedUsers.length === 1 ? 'space' : 'spaces'} 
            {' '} 
            left
          </Typography>
        </Box>
        
        <Box sx={{ marginTop: '15px' }}>
          <Typography fontWeight={'600'}>About This Class:</Typography>
          <Typography sx={{marginTop: '10px'}}>{classData.description}</Typography>
        </Box>

        <Box sx={{ marginTop: '15px' }}>
          <Typography fontWeight={'600'}>Who is this booking for (select at least one)?</Typography>
          {/* Checkbox for the main user */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedUsers.includes(user.dataValues.id)}
                  disabled={bookedMembers && isMemberAlreadyBooked(bookedMembers, user)}
                  id={String(user.dataValues.id)}  // Use a unique identifier for the main user
                  value={String(user.dataValues.id)}
                  onChange={() => handleCheckboxChange(user.dataValues.id)}
                />
              }
              label={`${user.dataValues.first_name} ${user.dataValues.last_name} (You)`}
            />
          </Box>
          
          {/* Map through family members and display them as checkboxes */}
          {user?.familyMembers.map((member: FamilyMember) => (
            <Box key={member.id}>
              <FormControlLabel
                control={
                <Checkbox
                  checked={checkedUsers.includes(member.id)}
                  disabled={bookedMembers && isFamilyMemberAlreadyBooked(bookedMembers, member)}
                  id={String(member.id)}
                  value={String(member.id)}
                  onChange={() => handleCheckboxChange(member.id)}
                />
                }
                label={`${member.first_name} ${member.last_name}`}
              />
            </Box>
          ))}
        </Box>

        {/* Book Class Button */}
        <form onSubmit={handleFormSubmit}>
        <Box sx={{ marginTop: '15px' }} textAlign={'center'} display={'flex'} justifyContent={'center'}>
          <Button id='submit-book-class' disabled={isSubmitButtonDisabled} type="submit" variant="contained" color="primary">Book Class</Button>
        </Box>
        </form>
        </>
      ) : (
       <p>No class data</p>
      )}
    </div>
    </Container>
  )
};