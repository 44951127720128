// Libs
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
// Hooks
import { useAuth } from "../hooks/useAuth";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { getTodaysEventsByClubName } from "../api/Events";
// Types
import { Event } from "../types/types";
// Components
import ClubLandingList from "../components/Events/ClubLandingList";
import Loading from "../components/Alerts/Loading";
// Material-UI
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
// Material-UI Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

export default function ClubLandingPage() {
  const navigate = useNavigate();
  const { user, isUserLoading } = useAuth();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  // useQuery hook from react-query to fetch todays classes
  const { data: todaysClasses, error, isLoading } = useQuery({
    queryKey: ['todaysEventsData', user?.dataValues?.club_name, user?.dataValues?.timezone],
    queryFn: () => getTodaysEventsByClubName(user?.dataValues?.club_name, user?.dataValues?.timezone),
    enabled: !!user?.dataValues?.club_name && !!user?.dataValues?.timezone
  });

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <>
    <Helmet>
      <title>{`${user?.dataValues?.club_name || ''} | Home`}</title>
    </Helmet>
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
        <Box textAlign={'center'} sx={{marginTop: '5px'}}>
          <Typography sx={{fontWeight: 'bold', padding: '10px'}}>Todays Classes - {DateTime.now().setZone(user?.dataValues?.timezone || 'Europe/London').toFormat('ccc dd LLL yyyy').toString()}</Typography>
        </Box>
        {/* If no classes show Create Class Button */}
        {todaysClasses?.length === 0 ? (
          <Box textAlign={'center'} sx={{marginTop: '15px'}}>
          <Card >
            <CardContent>
              <Box>
                <Typography sx={{fontWeight: '600'}}>No Classes Today</Typography>
                <LocalActivityIcon fontSize="large"/>
              </Box>
              <Button variant="contained" onClick={() => navigate('/createclass')}>Create Class</Button>
            </CardContent>
          </Card>
        </Box>
        ) : (
          <>
          {todaysClasses?.map((todaysClass: Event) => (
            <ClubLandingList key={todaysClass.id} todaysClass={todaysClass} user={user} navigate={navigate} />
          ))}
          </>
        )}
      </Container>
      </>
    );
};
