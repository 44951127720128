// Libs
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
// Hooks
import { useAuth } from '../hooks/useAuth';
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { editEventType, createEventType } from '../api/EventTypes';
// Global Context
import { useAlert } from '../AlertContext';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Box, Button, Container, FormControl, TextField, Typography } from '@mui/material';
// Form Validations
import { createClassTypeSchema } from '../validations/CreateClassTypeSchema';


export default function CreateClassType() {
  const [existingEvent, setExistingEvent] = useState(() => {
    const storedEvent = localStorage.getItem('existingEvent');
    return storedEvent ? JSON.parse(storedEvent) : null;
  });

  // Clear LocalStorage existingEvent
  useEffect(() => {
    localStorage.removeItem('existingEvent');
  }, []); // Empty dependency array means this effect runs only on mount and unmount
    
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { user, isUserLoading } = useAuth();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);
 
  const formik = useFormik({
    initialValues: {
    newEventTypeName: existingEvent?.name || '',
    newEventDescription: existingEvent?.description || '',
    eventTypeId: existingEvent?.id || null,
    userId: user?.dataValues?.id,
  },
    validationSchema: createClassTypeSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      sendRequest(values, resetForm);
      resetForm({
        values: {
          newEventTypeName: '',
          newEventDescription: '',
          eventTypeId: null,
          userId: user?.dataValues?.id,
        },
      });
      setExistingEvent(false);
    },
  });

  const sendRequest = async (data: any, resetForm: () => void) => {
    try {
      let response;
      if (existingEvent)  {
        response = await editEventType(data);
      } else {
        response = await createEventType(data);
      }

      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        localStorage.removeItem('existingEvent');
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  if (isUserLoading) {
    return <Loading text="" />
  }
  
    return (
      <Container component="main" maxWidth="sm" sx={{ pt: '64px' }}>
      {alert && (<AlertMessage />)}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      <Typography component="h2" variant="h6">
        {existingEvent ? (
          <>Edit Class Type</>
          ) : (
          <>Create Class Type</>
          )
        }
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 1 }}>
    
          {/* Class Name */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="newEventTypeName"
              label="Class Name"
              name="newEventTypeName"
              value={formik.values.newEventTypeName}
              onChange={formik.handleChange}
              error={formik.touched.newEventTypeName && Boolean(formik.errors.newEventTypeName)}
              helperText={formik.touched.newEventTypeName && formik.errors.newEventTypeName?.toString()}
            />
          </FormControl>

          {/* Class Description */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="newEventDescription"
              label="Class Description"
              name="newEventDescription"
              multiline
              rows={4}
              value={formik.values.newEventDescription}
              onChange={formik.handleChange}
              error={formik.touched.newEventDescription && Boolean(formik.errors.newEventDescription)}
              helperText={formik.touched.newEventDescription && formik.errors.newEventDescription?.toString()}
            />
          </FormControl>

        </Box>
        {/* Create Class Button */}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        {existingEvent ? (
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          disabled={formik.isSubmitting}
        >
          Update Class Type
        </Button>
        ) : (
          <Button
          type="submit"
          color="primary"
          variant="outlined"
          disabled={formik.isSubmitting}
        >
          Create Class Type
        </Button>

        )}

        </Box>
      </form>
    </Box>
  </Container>   
    );
  }