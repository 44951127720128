// Libs
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query'
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from '../hooks/useAuth';
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { getEventTypeByUserId } from '../api/EventTypes';
import { deleteEventType } from '../api/EventTypes';
// Types
import { EventType } from '../types/types';
// Components
import Loading from "../components/Alerts/Loading";
import EventTypeCard from '../components/Events/EventTypeCard';
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
// Material-UI Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

export default function EditClassType() {
  const { user, isUserLoading } = useAuth();
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const navigate = useNavigate();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  // useQuery hook from react-query
  const queryClient = useQueryClient();
  const { data: eventTypes, isLoading } = useQuery({
    queryKey: ['clubEventTypes', user?.dataValues?.id],
    queryFn: () => getEventTypeByUserId(user?.dataValues?.id),
    enabled: !!user?.dataValues?.id
  });

  const sendDeleteRequest = async (eventType: any) => {
    eventType.club_name = user?.dataValues?.club_name;
    try {
      const response = await deleteEventType(eventType);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['clubEventTypes', user?.dataValues?.id] });
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('An error has occurred', 'Please Try Again', 'error');
    }
  }
  
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
      <Box sx={{ marginTop: '15px', marginBottom: '15px', textAlign: 'center'}}>
        <Typography sx={{fontWeight: '600'}}>Current Class Types</Typography>
      </Box>

      {/* Create Class Type Button */}
      {eventTypes && eventTypes.length === 0 && (
      <Box textAlign={'center'} sx={{marginTop: '15px'}}>
        <Card >
          <CardContent>
            <Box>
              <Typography sx={{fontWeight: '600'}}>No Class Types Found</Typography>
              <LocalActivityIcon fontSize="large"/>
            </Box>
            <Button variant="contained" onClick={() => navigate('/createclasstype')}>Create Class Type</Button>
          </CardContent>
        </Card>
      </Box>
      )}

      {eventTypes && eventTypes.length > 0 && (
        eventTypes.map((eventType: EventType) => (
          <EventTypeCard key={eventType.id} eventType={eventType} navigate={navigate} sendDeleteRequest={sendDeleteRequest} />
        ))
      )}
    </Container>
  );
}