// Helpers
import { getAllClassesGroupedByDate } from '../helpers/Utilities';
// Types
import { Booking } from '../types/types';

export const retrieveAttendees = async (eventId: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/get_bookings_by_event_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({eventId}),
  });
  const json = await response.json();
  return json;
};

export const updateAttendance = async (bookingId: string, userId: string, attend: boolean, familyMemberId: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/confirm_attendance`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json', Accept: 'application/json'},
    body: JSON.stringify({
      didAttend: attend,
      bookingId: bookingId,
      userId: userId,
      familyMemberId: familyMemberId,
    }),
  });

  const json = await response.json();
  return json;
};

export const getAttendanceByClubName = async (clubName: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/get_attendance_summary`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({clubName}),
  });
  const json = await response.json();
  return json;
};

export const getBookingsByEventId = async (eventId: string) => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/get_bookings_by_event_id`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ eventId }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch bookings');
    }

    const json = await response.json();
    const data = json as Booking[];
    return data;
  } catch (error) {
    console.error('Error fetching bookings:', error);
    return []; // Return an empty array if an error occurs
  }
};

export const getBookingsByUserId = async (userId: string, timezone: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/get_bookings_by_user_id`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ userId }),
    });

    const json = await response.json();
    const data = getAllClassesGroupedByDate(timezone, json) as [string, Booking[]][];
    return data;
};

export const createNewBooking = async (body: any) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/create_new_booking`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: body,
  });

  const json = await response.json();
  return json;
};

export const deleteMemberBooking = async (userId: string, bookedUserId: string, eventId: string, clubName: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/bookings/api/delete_member_booking`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({userId, bookedUserId, eventId, clubName}),
  });
  const json = await response.json();
  return json;
};
