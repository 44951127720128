// Libs
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from '../hooks/useAuth';
import { useShowAlert } from "../hooks/useShowAlert";
// Api Requests
import { updateUserProfile, uploadImageToS3 } from '../api/Users';
// Validation
import { createProfileSchema } from '../validations/ProfileSchema';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Avatar, Box, Button, Container, FormControl, TextField, Typography } from '@mui/material';

export default function Profile() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { user, isUserLoading, updateUserData } = useAuth();
  const navigate = useNavigate();
  // Image Upload
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  // Trigger file selection dialog
  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: { target: { value: string; files: any[]; }; }) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        showAlert('Invalid file type', 'Please upload a JPEG or PNG image.', 'error');
        return;
      }

      // Check file size (e.g., 5MB max)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        showAlert('File is too large', 'Max Size 5MB.', 'error');
        return;
      }

      // If the file is valid, set the selectedImage state to the file's URL
      const fileUrl = URL.createObjectURL(file);
      setSelectedImage(fileUrl);
      await handleUpdateProfileImage(user?.dataValues?.id, file);
    }
  };

  useEffect(() => {
    // This function is called when the component is unmounted or the selectedImage changes
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage);
      }
    };
  }, [selectedImage]);
  
  // Edit Profile State
  const [isEditMode, setIsEditMode] = useState(false);
  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  // Restrict access to page if user is not of type 'club'
  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);

  const formik = useFormik({
    initialValues: {
    userId: user?.dataValues?.id || '',
    firstName: user?.dataValues?.first_name || '',
    lastName: user?.dataValues?.last_name || '',
    clubName: user?.dataValues?.club_name || '',
    email: user?.dataValues?.email || '',
    telephone: user?.dataValues?.telephone || '',
    timezone: user?.dataValues?.timezone || '',
  },
    validationSchema: createProfileSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      handleUpdateProfile(values);
    },
  });

  const handleUpdateProfile = async (data: any) => {
    try {
      const response = await updateUserProfile(data);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        // Store the updated user data
        const updatedUserData = response.user;
        // Update Formik's form state with the new data
        formik.setValues({
          userId: updatedUserData.id,
          firstName: updatedUserData.first_name,
          lastName: updatedUserData.last_name,
          clubName: updatedUserData.club_name,
          email: updatedUserData.email,
          telephone: updatedUserData.telephone,
          timezone: updatedUserData.timezone,
        });
        // Update the user context with the new data
        updateUserData(updatedUserData);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    } finally {
      formik.setSubmitting(false);
      setIsEditMode(false);
    }
  };

  const handleUpdateProfileImage = async (userId: string, file: File) => { 
    try {
      const response = await uploadImageToS3(userId, file);
      if (response.success) {
        let userData: { member_profile_image_url: string } | null = JSON.parse(localStorage.getItem('userData') || 'null');
        if (userData) {
          // Modify the property, for example 'name'
          userData.member_profile_image_url = response.image_url;

          // Convert the object back to a string and save it
          localStorage.setItem('userData', JSON.stringify(userData));
        }
        showAlert(response.success.message, response.success.subMessage, 'success');
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }    
  }

  if (isUserLoading) {
    return <Loading text="" />
  }

  return (
    <>
    <Helmet>
      <title>{`${user?.dataValues?.club_name || ''} | Profile`}</title>
    </Helmet>
    <Container component="main" maxWidth="xs" sx={{ pt: '64px' }}>
        {alert && (<AlertMessage />)}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 675,
            alignItems: 'center',
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Typography component="h1" variant="h5">
            Profile
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 3, scrollSnapAlign: 'center', }}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files && event.target.files.length > 0) {
                  const filesArray = Array.from(event.target.files); // Convert FileList to array
                  handleFileChange({ target: { files: filesArray, value: '' } });

                  setTimeout(() => {
                    event.target.value = '';
                  }, 0);
                }
              }}
              accept="image/*"
            />
              <Avatar
                sx={{ m: 1, width: 66, height: 66 }}
                src={selectedImage || user?.dataValues?.member_profile_image_url}
                alt="Profile Picture"
                onClick={handleAvatarClick}
              />
            </Box>

              {/* First Name */}
              <FormControl fullWidth error={formik.touched.firstName && Boolean(formik.errors.firstName)} sx={{ mb: 2 }}>
                <TextField
                  disabled={!isEditMode}
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  fullWidth
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  // helperText={formik.touched.firstName && formik.errors.firstName}
                />
                {/* <FormHelperText>{formik.touched.firstName && formik.errors.firstName}</FormHelperText> */}
              </FormControl>

              {/* Last Name */}
              <FormControl fullWidth error={formik.touched.lastName && Boolean(formik.errors.lastName)} sx={{ mb: 2 }}>
                <TextField
                  disabled={!isEditMode}
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  fullWidth
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  // helperText={formik.touched.lastName && formik.errors.lastName}
                />
                {/* <FormHelperText>{formik.touched.lastName && formik.errors.lastName}</FormHelperText> */}
              </FormControl>

              {/* Club Name */}
              <FormControl fullWidth error={formik.touched.clubName && Boolean(formik.errors.clubName)} sx={{ mb: 2 }}>
                <TextField
                  disabled
                  id="clubName"
                  label="Club Name"
                  name="clubName"
                  fullWidth
                  required
                  value={formik.values.clubName}
                  onChange={formik.handleChange}
                  error={formik.touched.clubName && Boolean(formik.errors.clubName)}
                  // helperText={formik.touched.clubName && formik.errors.clubName}
                />
                {/* <FormHelperText>{formik.touched.clubName && formik.errors.clubName}</FormHelperText> */}
              </FormControl>

              {/* Email */}
              <FormControl fullWidth error={formik.touched.email && Boolean(formik.errors.email)} sx={{ mb: 2 }}>
                <TextField
                  disabled={!isEditMode}
                  id="email"
                  label="Email"
                  name="email"
                  fullWidth
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                {/* <FormHelperText>{formik.touched.email && formik.errors.email}</FormHelperText> */}
              </FormControl>

              {/* Telephone */}
              <FormControl fullWidth error={formik.touched.telephone && Boolean(formik.errors.lastName)} sx={{ mb: 2 }}>
                <TextField
                  disabled={!isEditMode}
                  id="telephone"
                  label="Telephone"
                  name="telephone"
                  fullWidth
                  required
                  value={formik.values.telephone}
                  onChange={formik.handleChange}
                  error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                  // helperText={formik.touched.telephone && formik.errors.telephone}
                />
                {/* <FormHelperText>{formik.touched.telephone && formik.errors.telephone}</FormHelperText> */}
              </FormControl>

              {/* Timezone */}
              <FormControl fullWidth error={formik.touched.timezone && Boolean(formik.errors.timezone)} sx={{ mb: 2 }}>
                <TextField
                  id="timezone"
                  label="Timezone"
                  name="timezone"
                  fullWidth
                  required
                  disabled
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                  error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                  // helperText={formik.touched.timezone && formik.errors.timezone}
                />
                {/* <FormHelperText>{formik.touched.timezone && formik.errors.timezone}</FormHelperText> */}
              </FormControl>
            </Box>
            {/* Edit/ Save Profile Button */}
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={handleEditClick}
                color={isEditMode ? "error" : "primary"}
                variant="outlined"
                sx={{ mr: 1 }}
              >
              {isEditMode ? "Cancel" : "Edit"}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={!isEditMode || formik.isSubmitting}
              >
                Save Profile
              </Button>
            </Box>
          </form>

        </Box>
    </Container>
    </>
  );
}