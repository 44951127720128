// Libs
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// Hooks
import { useAuth } from '../hooks/useAuth';
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { getAttendanceByClubName } from "../api/Bookings";
// Helpers
import { showFriendlyDate } from '../helpers/Utilities';
// Components
import Loading from "../components/Alerts/Loading";
// Material-UI
import { Avatar, Box, Button, Card, CardContent, Container, FormControl, Grid, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, Typography, Tab } from '@mui/material';
// Material-UI Icons
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

export default function AttendanceSummary() {
  const { user, isUserLoading } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  const queryClient = useQueryClient();
  const { data: members, isLoading } = useQuery({
    queryKey: ['members', user?.dataValues?.club_name],
    queryFn: () => getAttendanceByClubName(user?.dataValues?.club_name),
    enabled: !!user?.dataValues?.club_name
  });

  const filteredMembers = members && members.length > 0
    ? members.reduce((acc: any[], member: { first_name: string; last_name: string; }) => {
      // Check if the member's name matches the search term
      const nameMatch = searchTerm
        ? member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        : true;

      // If there's a match, add the member to the accumulator
      if (nameMatch) {
        acc.push(member);
      }

      return acc;
    }, [])
    : [];

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedMembers = [...filteredMembers].sort((a, b) => {
    if (orderBy === 'last_attended_date') {
      const dateA = a[orderBy] === '-' ? new Date(0) : new Date(a[orderBy]);
      const dateB = b[orderBy] === '-' ? new Date(0) : new Date(b[orderBy]);
      return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    } else {
      if (typeof a[orderBy] === 'string') {
        return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
      } else {
        return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
      }
    }
  });

  const resetFilter = () => {
    setSearchTerm('');
  };

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }
  

  return (
    <>
      <Helmet>
        <title>{`${user?.dataValues?.club_name || ''} | Attendance Summary`}</title>
      </Helmet>
        <Container component="main" maxWidth="md" sx={{ pt: '64px' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: 675,
              alignItems: 'center',
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            <Typography component="h1" variant="h5" marginTop={2}>
              Attendance Summary
            </Typography>

            <Grid container spacing={2} sx={{ padding: '20px', width: '100%' }} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={9} md={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="member-name"
                  label="Search....."
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3} display="flex" justifyContent="center">
              <Button variant="outlined" onClick={resetFilter}>Reset</Button>
            </Grid>
          </Grid>

            {sortedMembers?.length === 0 && !isLoading ? (
              <Box textAlign={'center'} sx={{ marginTop: '15px', width: '80%' }}>
                <Card >
                  <CardContent>
                    <Box>
                      <Typography sx={{ fontWeight: '600' }}>No Members Found</Typography>
                      <LocalActivityIcon fontSize="large" />
                    </Box>
                    <Button variant="contained" onClick={() => navigate('/')}>Home</Button>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table>
                  {!isLoading && sortedMembers.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'classes_booked'}
                            direction={orderBy === 'classes_booked' ? order : 'asc'}
                            onClick={() => handleRequestSort('classes_booked')}
                          >
                            Booked
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'classes_attended'}
                            direction={orderBy === 'classes_attended' ? order : 'asc'}
                            onClick={() => handleRequestSort('classes_attended')}
                          >
                            Attended
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'classes_missed'}
                            direction={orderBy === 'classes_missed' ? order : 'asc'}
                            onClick={() => handleRequestSort('classes_missed')}
                          >
                            Missed
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'attendance_rate'}
                            direction={orderBy === 'attendance_rate' ? order : 'asc'}
                            onClick={() => handleRequestSort('attendance_rate')}
                          >
                            %
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'last_attended_date'}
                            direction={orderBy === 'last_attended_date' ? order : 'asc'}
                            onClick={() => handleRequestSort('last_attended_date')}
                          >
                            Last Attended
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    )}
                    <TableBody>
                      {sortedMembers?.map((member: any) => (
                        <TableRow key={member.id}>
                          <TableCell component="th" scope="row" sx={{ minWidth: 125, display: 'flex', alignItems: 'center' }}>
                          <Avatar src={member.member_profile_image_url} sx={{ marginRight: 2 }} />
                            {member.first_name} {member.last_name}
                          </TableCell>
                          <TableCell align="right">{member.classes_booked}</TableCell>
                          <TableCell align="right">{member.classes_attended}</TableCell>
                          <TableCell align="right">{member.classes_missed}</TableCell>
                          <TableCell align="right">{member.attendance_rate}%</TableCell>
                          <TableCell align="right">{isNaN(Date.parse(member.last_attended_date)) ? member.last_attended_date : showFriendlyDate(member.last_attended_date, member.timezone)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

          </Box>
        </Container>
    </>
  );
}