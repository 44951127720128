// Libs
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from "../hooks/useAuth";
import { useShowAlert } from "../hooks/useShowAlert";
import useMemberRedirect from '../hooks/useMemberRedirect';
// Api Requests
import { getBookingsByUserId, deleteMemberBooking } from "../api/Bookings";
// Types
import { Booking } from "../types/types";
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import MemberLandingList from "../components/Events/MemberLandingList";
import ActiveBookingsButton from "../components/Bookings/ActiveBookingsButton";
// Material-UI
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
// Material-UI Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function UserLandingPage() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const navigate = useNavigate();
  const { user, isUserLoading } = useAuth();

  // Redirect user if not a member user
  useMemberRedirect(user, isUserLoading);

  const sendCancelRequest = async (userId: string, bookedUserId: string, eventId: string, clubName: string) => {
    try {
      const response = await deleteMemberBooking(userId, bookedUserId, eventId, clubName);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['memberBookings', userId, user?.dataValues?.timezone] });
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('An error has occurred', 'Please Try Again', 'error');
    }
  }

  // Retrieve existing bookings for member
  const queryClient = useQueryClient();
  const { data: bookingsData, error: existingBookingsError, isLoading: isBookingsLoading } = useQuery({
    queryKey: ['memberBookings', user?.dataValues?.id, user?.dataValues?.timezone],
    queryFn: () => getBookingsByUserId(user?.dataValues?.id, user?.dataValues?.timezone),
    enabled: !!user?.dataValues?.id && !!user?.dataValues?.timezone,
  });
 
  // If still loading, show loading state
  if (isUserLoading || isBookingsLoading) {
    return <Loading text="" />
  }

  if (existingBookingsError) return <p>An error has occurred: {existingBookingsError.message}</p>;

  return (
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
      <Box textAlign={'center'} sx={{marginTop: '5px'}}>
        <Typography sx={{fontWeight: 'bold', padding: '10px'}}>Hi, {user?.dataValues?.first_name}</Typography>
      </Box>

      {/* Active Bookings Banner */}
      <ActiveBookingsButton bookingsData={bookingsData}/>
        
      {/* Book A Class Button */}
      {bookingsData?.length === 0 && (
      <Box textAlign={'center'} sx={{marginTop: '15px'}}>
        <Card >
          <CardContent>
            <Box>
              <CalendarMonthIcon fontSize="large"/>
            </Box>
            <Button variant="contained" onClick={() => navigate('/bookclasses')}>Book A Class Now</Button>
          </CardContent>
        </Card>
      </Box>
      )}

      {/* Active Bookings */}
      {(bookingsData?.length ?? 0) > 0 && bookingsData && bookingsData.map(([date, classItems]: [string, Booking[]]) => (
        <MemberLandingList key={date} date={date} classItems={classItems} user={user} navigate={navigate} sendCancelRequest={sendCancelRequest} />
      ))}

    </Container>
  );
};
