// Libs
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
// Hooks
import { useAuth } from '../hooks/useAuth';
// Api Requests
import { getEventTypeByUserId } from '../api/EventTypes';
// Helpers
import { getAllClassesGroupedByDate } from '../helpers/Utilities';
// Types
import { Event } from '../types/types';
// Components
import AllClubClassesList from '../components/Events/AllClubClassesList';
import ScrollToTop from '../components/Scroll/ScrollToTop';
import Loading from "../components/Alerts/Loading";
import PullToRefresh from "../components/Refresh/PullToRefresh";
import FilterEvents from '../components/Events/FilterEvents';
// Material-UI
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
// Material-UI Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const retrieveClasses = async (clubName: string, timezone: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/get_events_by_club_name`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({clubName}),
  });
  const json = await response.json();
  const data = getAllClassesGroupedByDate(timezone, json) as [string, Event[]][];
  return data
};

export default function SearchClasses() {
  const { user, isUserLoading } = useAuth();
  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState<DateTime | null>(null);
  const [selectedClassName, setSelectedClassName] = useState('');
 
  // useQuery hook from react-query
  const queryClient = useQueryClient();
  const { data: allClasses, error, isLoading } = useQuery({
    queryKey: ['clubEvents', user?.dataValues?.club_name, user?.dataValues?.timezone],
    queryFn: () => retrieveClasses(user?.dataValues?.club_name ?? '', user?.dataValues?.timezone ?? '')
  });

  const { data: eventTypes, error: eventTypesError, isLoading: eventTypesLoading } = useQuery({
    queryKey: ['clubEventTypes', user?.dataValues?.id],
    queryFn: () => getEventTypeByUserId(user?.dataValues?.id ?? '')
  });

  const filteredClasses = allClasses?.reduce((acc: [string, Event[]][], [dateString, events]) => {
    const filteredEvents = events.filter(event => {
      const eventDate = DateTime.fromISO(event.date);
      const eventDateMatch = filterDate ? eventDate.hasSame(filterDate, 'day') : true;
      const eventNameMatch = selectedClassName ? event.name.toLowerCase() === selectedClassName.toLowerCase() : true;
  
      // If only class name is selected, ignore the date filter
      // If both are selected, apply both filters
      // If only date is selected, apply only the date filter
      return eventNameMatch && (eventDateMatch || !filterDate);
    });
  
    if (filteredEvents.length > 0) {
      acc.push([dateString, filteredEvents]);
    }
  
    return acc;
  }, []);

  const resetFilters = () => {
    setSelectedClassName('');
    setFilterDate(null);
  };
  
  const handleRefresh = async () => {
    // Start both the timeout and invalidation process simultaneously
    // Artificial delay to show the loading state so the user knows something is happening
    const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1000)); // Artificial delay
    const invalidationPromise = queryClient.invalidateQueries({
      queryKey: ['clubEvents', user?.dataValues?.club_name, user?.dataValues?.timezone]
    });
  
    // Wait for both promises to complete
    await Promise.all([timeoutPromise, invalidationPromise]);
  };
 
  // Restrict access to page if user is not of type 'club'
  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
      if (user?.dataValues?.user_type !== 'club') {
        navigate('/bookclasses'); // Redirect to member search classes page if not a member club
        return;
      }
    }
  }, [user, isUserLoading, navigate]);


  if (isUserLoading || isLoading|| eventTypesLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;
  if (eventTypesError) return <p>An error has occurred: {eventTypesError.message}</p>;

  return (
    <>
    <Helmet>
      <title>{`${user?.dataValues?.club_name || ''} | Search Classes`}</title>
    </Helmet>
    <PullToRefresh onRefresh={handleRefresh} >
    <div id="back-to-top-anchor" />
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
    <Box textAlign={'center'} sx={{marginTop: '5px'}}>
      <Typography sx={{fontWeight: 'bold', padding: '10px'}}>All Classes</Typography>
    </Box>

    {/* Filter Events Component */}
    <FilterEvents selectedClassName={selectedClassName} setSelectedClassName={setSelectedClassName} filterDate={filterDate} setFilterDate={setFilterDate} eventTypes={eventTypes?? []} resetFilters={resetFilters} />

    {filteredClasses?.length === 0 ? (
      <Box textAlign={'center'} sx={{marginTop: '15px'}}>
        <Card >
          <CardContent>
            <Box>
              <Typography sx={{fontWeight: '600'}}>No Classes Available</Typography>
              <LocalActivityIcon fontSize="large"/>
            </Box>
            <Button variant="contained" onClick={() => navigate('/createclass')}>Create Class</Button>
          </CardContent>
        </Card>
      </Box>
    ) : (
      <>
      {filteredClasses?.map(([date, classItems]: [string, Event[]]) => (
        <AllClubClassesList key={date} date={date} classItems={classItems} user={user} navigate={navigate} />
      ))}
      <ScrollToTop />
      </>
    )}
  </Container>
  </PullToRefresh>
  </>
  ); 
}